import React from "react";
import Banner from "../components/banner";
import BannerCard from "../components/bannerCard";
import Services from "../components/services";
import Header from "../components/header";
import Footer from "../components/footer";
import ContactUs from "../components/Contactus";
import { Container } from "react-bootstrap";
import trust from "../assets/image/trust.png";

import AchauffeursIMG from "../assets/image/Achauffeurs__1.png";
import australiachauffeurgroupIMG from "../assets/image/australiachauffeurgroup.png";
import British_Airport_carsIMG from "../assets/image/British_AIrport_Cars.png";
import BrownHillCorporateHireIMG from "../assets/image/BrownHillCorporateHire.png";
import chauffeurondemandIMG from "../assets/image/chauffeurondemand.png";
import silverstarchauffeursIMG from "../assets/image/silverstarchauffeurs.png";

import bannerVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM.mp4";

import Calen from "../assets/image/urride-banner.png";
import NewBannerCard from "../components/new-banner-card/NewBannerCard";
import PriceTable from "../components/price-table/PriceTable";
import SubscriptionPlan from "../components/SubscriptionPlan";
import FAQ from "../components/FAQ";
import { Link } from "react-router-dom";
import NewContactUs from "../components/contact-us/NewContactUs";

const clientLogo = [
  { image: AchauffeursIMG },
  { image: australiachauffeurgroupIMG },
  { image: British_Airport_carsIMG },
  { image: BrownHillCorporateHireIMG },
  { image: chauffeurondemandIMG },
  { image: silverstarchauffeursIMG },
];

const Index = (props) => {
  return (
    <>
      <Header title={props.metaTitle} desc={props.metaContent} />
      <Banner />
      <div className="hide-in-small">
        {/* <BannerCard /> */}
        <NewBannerCard />
      </div>
      <div className="hide-in-large">
        {/* <BannerCard /> */}
        <NewBannerCard />
      </div>

      <div className="services">
        <Container>
          <h3 className="text-center hav-bg">
            <span style={{ color: "#000" }}>
              All in One Highly Trusted Robust Cloud Limo Reservation Dispatch
              System
              <div className="content">
                <p>
                  We provide you all in one cloud Limo Reservation system with
                  all the technical resources. required from start to finish to
                  run your application.Our Dispatch system allows you to track
                  your drivers and monitor the jobs and payment activity in
                  real-time
                </p>
              </div>
              <a
                href="https://www.trustpilot.com/review/www.taxi-webdesign.com"
                target="_blank"
              >
                <img src={trust} />
              </a>
              {/* =====atoz-recent-logo==== */}
              <div className="recent-client-div">
                <p style={{ color: "#000", marginBottom: "0px" }}>
                  Our Recent Client
                </p>
                <div className="recent-logo-ctn">
                  {clientLogo.map((item, ind) => (
                    <div className="single-image" key={ind}>
                      <img src={item.image} alt="cars" />
                    </div>
                  ))}
                </div>
              </div>
              {/* ============= */}
              {/* <a
                href="https://accounts.urride.online/register/"
                className="get-started"
              >
                Order Now
              </a> */}
              <div>
                <Link to={"https://buy.stripe.com/fZe8A61exdjhas014Q"}>
                  <button className="mid-outlined-btn">Order Now</button>
                </Link>
              </div>
            </span>
          </h3>
          <div className="arrow">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="592.000000pt"
              height="421.000000pt"
              viewBox="0 0 592.000000 421.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,421.000000) scale(0.100000,-0.100000)"
                fill="#54A937"
                stroke="none"
              >
                <path
                  d="M1301 3921 c-17 -11 -21 -9 199 -106 220 -98 378 -177 511 -256 659
-393 1141 -938 1436 -1624 58 -133 107 -271 167 -465 32 -102 65 -201 73 -220
l15 -35 10 25 c25 64 -24 394 -92 620 -57 191 -102 302 -200 495 -146 288
-301 505 -529 739 -323 330 -721 586 -1141 734 -172 60 -419 111 -449 93z"
                />
                <path
                  d="M4585 2323 c-46 -63 -259 -494 -323 -653 -162 -403 -254 -802 -269
-1168 -3 -84 -9 -151 -13 -150 -4 2 -105 116 -225 253 -338 387 -464 524 -675
735 -186 186 -273 261 -287 247 -29 -30 350 -449 883 -977 l328 -325 8 55 c57
390 146 699 347 1213 183 464 298 817 267 817 -3 0 -21 -21 -41 -47z"
                />
              </g>
            </svg>
          </div>

          <div className="video-wrapper hide-in-small">
            <video width="100%" className="video-frame" controls="false">
              <source src={bannerVideo} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </div>
        </Container>
      </div>
      <Services />

      {/* <div className="hide-in-large">
        <NewBannerCard />
      </div> */}

      <FAQ />
      <SubscriptionPlan />
      <PriceTable />
      {/* <ContactUs /> */}
      <NewContactUs />
      <Footer />
    </>
  );
};
export default Index;
