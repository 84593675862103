import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import CookiesPolicy from "./pages/cookiesPolicy";
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/terms-of-service"
          element={
            <TermsOfService
              metaTitle="Agreement for Using A To Z Dispatch | Terms and Conditions"
              metaContent="Before using A To Z Dispatch, please read and understand our terms and conditions. Our agreement outlines the rules and regulations for using our platform and creating your website."
            />
          }
        />

        <Route
          exact
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              metaTitle="How We Protect Your Privacy on A To Z Dispatch"
              metaContent="At A To Z Dispatch, we are committed to protecting your privacy. Our privacy policy outlines how we collect, use, and protect your personal information when you use our platform to create your website."
            />
          }
        />

        <Route
          exact
          path="/cookies-policy"
          element={
            <CookiesPolicy
              metaTitle="Understanding Cookies on A To Z Dispatch "
              metaContent="Our website builder uses cookies to enhance your browsing experience. Learn more about our cookies policy and how we use cookies to improve our website functionality."
            />
          }
        />

        <Route
          exact
          path="/"
          element={
            <Index
              metaTitle="Limo Reservation Software | Limo Software and Livery Dispatch"
              metaContent="A to Z Dispatch is in all-in-one Limo Reservation Software solution. Your Limo booking software, limo dispatch software, phone line, payment processor"
            />
          }
        />
        <Route
          path="*"
          element={
            <NotFound
              metaTitle="Limo Reservation Software | Not Found"
              metaContent=""
            />
          }
        />
      </Routes>
    </>
  );
};
export default App;
