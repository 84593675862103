import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/footer";
import Header from "../components/header";
import { Link } from "react-router-dom";
import { MdHome } from "react-icons/md";

const CookiesPolicy = (props) => {
  return (
    <>
      <Header title={props.metaTitle} desc={props.metaContent} />
      <div className="cookies-policy not-found">
        <Container>
          <h1>404 - Page Not Found</h1>
          <div className="privacy-policy-text">
            <h5 className="text-center">
              Sorry, the page you are looking for does not exist
            </h5>
            <div className="text-center">
              <Link className="btn btn-primary" to="/">
                <MdHome size={20} /> Go to Home Page
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default CookiesPolicy;
