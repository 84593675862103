import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import bannerVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM.mp4";

const Banner = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2500,
    fade: true,
    autoplaySpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="banner">
        <div className="banner-content">
          <Slider {...settings}>
            <div>
              <h1>
                Get White Label Limo Reservation Dispatch System with Robust
                Booking App.
              </h1>
              <p>No Booking Commission</p>
              {/* <h1>
                Get White Label Dispatch System with Robust Booking App.
                <span style={{ color: "#101010" }}> No Booking Commission</span>
              </h1> */}
            </div>
          </Slider>

          {/* <div
            style={{ marginTop: "20px" }}
            className="only-small hide-in-large "
          >
            <a href="#products" className=" anchor0  ">
              Starting now at Just{" "}
              <strong>
                <big style={{ fontSize: "20px", color: "#ec6408" }}>
                  $21/month
                </big>{" "}
              </strong>
            </a>
          </div> */}

          <div style={{ marginTop: "30px" }} className="anchor-group">
            {/* <a
              href="#products"
              style={{ color: "#101010" }}
              className="hide-in-small anchor anchor1 "
            >
              Starting at Just{" "}
              <strong>
                <big style={{ fontSize: "28px", color: "#ec6408" }}>
                  $21/month
                </big>{" "}
              </strong>
            </a> */}
            <div>
              <img className="price-img" src="/price-tag.png" alt="price-tag" />
            </div>
          </div>

          <div className="video-wrapper hide-in-large">
            <video width="100%" className="video-frame" controls="false">
              <source src={bannerVideo} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
